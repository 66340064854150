// import jQuery from 'jQuery'
import SmoothScroll from 'smooth-scroll'
import browser from 'browser-detect'
import ScrollTrigger from '@terwanerik/scrolltrigger'
import 'Includes/jquery.mosaic-in-0.5.js'


const result = browser();
const trigger = new ScrollTrigger();

trigger.add('[data-trigger]', {
    once: true,
    offset: {
        element: {
            x: 0,
            y: (trigger, rect, direction) => {
                return 0.3
            }
        },
        viewport: {
            x: 0,
            y: (trigger, frame, direction) => {
                return trigger.visible ? 0 : 0.1
            }
        }
    },
});

(function($){
    $('html').addClass(result.name);
    $('.main__background').mosaicIn({
        duration: 800,
        horizontalPixels: 64
    });
})(jQuery);

let scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true
});
